.qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px; }

.qr-code svg {
  width: 120px;
  height: 120px;
  flex-shrink: 0; }
  .qr-code svg:hover {
    fill: #1010A0; }
