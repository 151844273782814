.order-card .body {
  display: flex;
  flex-direction: column; }

.order-items-panel {
  flex-grow: 1; }

.order-total-panel {
  border-top: 1px solid #a0a0a0; }

.order-items-panel, .order-total-panel {
  padding: 1rem; }
  .order-items-panel .order-item, .order-total-panel .order-item {
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: #58585A; }
    .order-items-panel .order-item .name, .order-total-panel .order-item .name {
      text-transform: capitalize;
      flex-grow: 1; }

.payment-panel {
  padding: 1rem;
  display: flex;
  justify-content: flex-end; }
  .payment-panel button {
    margin-left: 1rem; }

.product-selection-card .body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  background-color: #a0a0a0; }
  .product-selection-card .body > div {
    background-color: white; }

.product-button {
  padding: 1.5rem;
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .product-button:hover {
    padding: 1rem; }
    .product-button:hover img {
      filter: brightness(70%); }
